/*
 * Spécificité
 * de mise en page
 */

/*
 * HEADER
 */
.l-header {
  // Navbar
  .navbar {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: $size-6;

    > .container,
    .container > .navbar {
      .navbar-brand {
        margin-left: 0; }
      .navbar-menu {
        margin-right: -.975rem; } }

    .navbar-brand {
      flex-grow: 2;
      .navbar-item {
        flex-grow: 1;
        text-align: center;
        justify-content: center;
        &:first-child {
          padding-left: 0; }
        &.is-logo {
          position: relative;
          width: 90px;
          max-width: 90px;
          flex-shrink: 1;
          img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            max-height: 100px;
            box-shadow: 0 0 5px 0 rgba(56,56,56,.78); } } } }

    .navbar-item,
    .navbar-link {
      padding-left: 0.975rem;
      padding-right: 0.975rem; }
    .navbar-menu {
      @include until($navbar-breakpoint) {
        .navbar-start,
        .navbar-end {
          display: flex;
          flex-direction: column;
          align-items: flex-end; } } }
    &.is-primary {
      .container {

        .navbar-brand {
          & > .navbar-item,
          .navbar-link {
            background-color: $black-bis; }

          & > a.navbar-item,
          .navbar-link {
            &:hover,
            &.is-active {
              color: $primary;
              background-color: $white; } } } } } } }

/*
 * Contenu juste apres le header
 */
.l-header + section {
  padding-top: 6rem; }


