@charset "utf-8";
/*
 * Surcharge de l'extension Bulma-checkradio "buma-radio.sass"
 */


// CRM IMMO : correction pour centrer la puce
.is-checkradio[type="radio"],
.is-checkradio[type="checkbox"].is-squareCheck {
   + label::after {
    transform: scale(0.52) !important; } }

// CRM IMMO Checkbox Ajout du style ".is-squareCheck"
.is-checkradio[type="checkbox"].is-squareCheck {
  + label {
    &::after,
    &:after {
      transform: translateY(0) rotate(0);
      background: $primary;
      left: 0;
      top: 0;
      transform: scale(0.5); } }

  @include radio-size($size-normal);
  &.is-small {
    @include radio-size($size-small); }
  &.is-medium {
    @include radio-size($size-medium); }
  &.is-large {
    @include radio-size($size-large); }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      &.has-background-color {
        + label {
          &::before,
          &:before {
            border-color: transparent !important;
            background-color: $color !important; } } }
      &:hover:not([disabled]) {
        + label {
          &::before,
          &:before {
            border-color: $color !important; } } }
      &:checked {
        + label {
          &::after,
          &:after {
            background-color: $color !important; } }
        &.has-background-color {
          + label {
            &::before,
            &:before {
              border-color: transparent !important;
              background-color: $color !important; }
            &::after,
            &:after {
              background-color: $color-invert !important; } } } }
      &.is-block {
 } } } }        // HB : je n'ai pas traité les 'is-block'
