@charset "utf-8";

// Centre le contenu des cellules
.has-cells-vcentered td,
.has-cells-vcentered th,
td.is-vcentered,
th.is-vcentered {
	vertical-align: middle; }

// Ligne princpale (toujours ouverte)
tr.table-row-main {
	.table-row-main-toggle2 {
		width: 114px;
		display: inline-flex;
		justify-content: space-between;
		span.icon {
			margin-right: calc(-.375em - 1px);
			margin-left: 0; }
		.toggle2--b {
			display: none; } }
	&.is-informations-active {
		.table-row-main-toggle {
			i {
				&:before {
					content: '\f068'; } } }
		.table-row-main-toggle2 {
			@extend .is-primary;
			.toggle2--a {
				display: none; }
			.toggle2--b {
				display: block; } } } }

// Ligne d'informations supplémentaire (repliè par défaut)
tr.table-row-information {
	// display: none
	visibility: hidden;
	border-top-color: transparent;

	&,
	td,
	td > div {
		padding: 0 !important;
		height: 0;
		max-height: 0;
		overflow: hidden;
		border-bottom-color: transparent; }

	td > div {
		// HB : l'effet de hover ne fonctionne pas. Aucune idée de pourquoi...
		opacity: 0;
		transition: opacity 0.12s ease 0.05s; }
	&:not(.is-selected) {
		// background-color: darken($table-striped-row-even-background-color, 10%) !important
		// $color: darken($table-striped-row-even-background-color, 10%)
		$c1: lighten($border, 6%);
		$c2: lighten($border, 8%);
		background: linear-gradient(to bottom, $c1 0%, $c2 50%, $c1 100%) !important; }
	&.is-active {
		visibility: visible;
		&,
		td,
		td > div {
			height: auto;
			max-height: 500px; }
		td {
			padding: 1em 0.5em 0.75em 1.25em !important;
			border-bottom-color: darken($border, 15%); }
		td > div {
			opacity: 1; } } }



// Tableau avec les row d'information supplémentaire
.table.has-table-row-information {
	&.is-striped {
		tbody {
			tr:not(.is-selected) {
				&:nth-child(even) {
					background-color: transparent; }
				&:nth-child(3),
				&:nth-child(4n+3) {
					background-color: $table-striped-row-even-background-color; } } } } }
