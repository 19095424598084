/*
 * Extension sur mesure
 * Case à coché personalisée créée spécialement
 *
 * Extension compatible avec les tailles des champs inputs de bulma
 *
 * Permet aussi de styler un champ input (text) pour qu'il ressemble au case
 *
 */

$vadimmCheckradiocase-radius: $radius !default;
$vadimmCheckradiocase-color: $grey !default;
$vadimmCheckradiocase-color-checked: $white !default;
$vadimmCheckradiocase-border-size: 1px !default;
$vadimmCheckradiocase-border-color: $border !default;
$vadimmCheckradiocase-border-hover-color: $grey !default;
$vadimmCheckradiocase-border-checked-color: $primary !default;
$vadimmCheckradiocase-border-checked-size: 1px !default;
$vadimmCheckradiocase-border-input-checked-size: 2px !default;
$vadimmCheckradiocase-bg-checked: $primary !default;

$vadimmCheckradiocase-cloud-radius: 0 !default;
$vadimmCheckradiocase-cloud-color: $text !default;
$vadimmCheckradiocase-cloud-color-hover: $text !default;
$vadimmCheckradiocase-cloud-color-checked: $white !default;
$vadimmCheckradiocase-cloud-bg: transparent !default !default;
$vadimmCheckradiocase-cloud-bg-hover: $grey-lighter !default;
$vadimmCheckradiocase-cloud-bg-checked: $dark !default;

.vadCheckradiocase[type="radio"],
.vadCheckradiocase[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0; }

.vadCheckradiocase {
  + label,
  &.is-input {
    @include input;
    // box-shadow: $input-shadow
    padding-left: calc(0.825em - 1px);
    padding-right: calc(0.825em - 1px);
    font-weight: $weight-medium;
    max-width: 100%;
    width: auto;
    color: $vadimmCheckradiocase-color;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    border: $vadimmCheckradiocase-border-size solid $vadimmCheckradiocase-border-color;
    border-radius: $vadimmCheckradiocase-radius;
    transition: border-color 0.16s ease-in, color 0.26s ease-in; }

  &:hover,
  &.is-hovered {
    + label,
    &.is-input {
      border-color: $vadimmCheckradiocase-border-hover-color; } }

  // &:focus,
  // &.is-focused

  &:active,
  &:checked,
  &.is-active {
    + label,
    &.is-input {
      border-color: $vadimmCheckradiocase-border-checked-color;
      color: $vadimmCheckradiocase-color-checked;
      background-color: $vadimmCheckradiocase-bg-checked;
      overflow: hidden;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        content: '';
        box-sizing: border-box;
        border-style: solid;
        border-color: $vadimmCheckradiocase-border-checked-color;
        border-width: $vadimmCheckradiocase-border-checked-size;
        border-radius: $vadimmCheckradiocase-radius;
        background-color: $vadimmCheckradiocase-bg-checked;
        z-index: -1; } }
    &.is-input {
      background-color: $vadimmCheckradiocase-bg-checked;
      border-width: $vadimmCheckradiocase-border-input-checked-size; } }

  &[disabled],
  &.disabled {
    + label,
    &.is-input {
      opacity: 0.5;
      cursor: not-allowed; } }

  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-inverted: nth($pair, 2);
    &.is-#{$name} {
      &:hover,
      &.is-hovered {
        + label,
        &.is-input {
          border-color: lighten($color, 20%);
          color: lighten($color, 20%); } }
      &:focus,
      &.is-focused,
      &:active,
      &:checked,
      &.is-active {
        + label,
        &.is-input {
          border-color: $color;
          background-color: $color;
          // color: $color
          color: $color-inverted;
          &:after {
            border-color: $color;
            background-color: $color; } } } } }

  // Sizes
  &.is-small {
    + label,
    &.is-input {
      @include control-small; }
    &:focus,
    &.is-focused,
    &:active,
    &:checked,
    &.is-active {
      + label,
      &.is-input {
        &:after {
          border-radius: 2px; } } } }
  &.is-medium {
    + label,
    &.is-input {
      @include control-medium; } }
  &.is-large {
    + label,
    &.is-input {
      @include control-large; } }
  // Modifiers
  &.is-fullwidth {
    + label,
    &.is-input {
      // display: block
      width: 100%; } }
  &.is-inline {
    + label,
    &.is-input {
      display: inline;
      width: auto; } }

  // Composition / layout
  + .vadCheckradiocase {
    margin-left: 0.5em; } }

// Nuage de checkbox
// Doit etre monté avec columns et  cols
.has-cloud-checkradiocase {
  .column {
    margin-left: -1px !important;
    margin-top: -1px !important; }
  .vadCheckradiocase {
    + label,
    &.is-input {
      // margin-top: -1px
      // margin-left: -1px
      color: $vadimmCheckradiocase-color;
      background-color: $vadimmCheckradiocase-cloud-bg;
      border: 1px solid $border;
      border-radius: $vadimmCheckradiocase-cloud-radius;
      transition: background-color 0.14s ease-in, color 0.2s ease-in;
      &:after {
        display: none !important; }
      &:before {
        position: static;
        display: inline-block;
        margin: 0 3px 0 0;
        border-width: 0;
        border: none;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        font-size: 0.9em;
        content: "\f00c";
        opacity: 0;
        transition: opacity 0.16s ease; } }

    &:hover,
    &.is-hovered {
      + label,
      &.is-input {
        color: $vadimmCheckradiocase-cloud-color-hover;
        background-color: $vadimmCheckradiocase-cloud-bg-hover; } }

    // &:focus,
    // &.is-focused

    &:active,
    &:checked,
    &.is-active {
      + label,
      &.is-input {
        color: $vadimmCheckradiocase-cloud-color-checked;
        background-color: $vadimmCheckradiocase-cloud-bg-checked;
        &:before {
          opacity: 1; } } } } }
