/*
 * Override de l'élément "notification" de bulma
 */
.notification {}

// forceFullWidth ajout du modificateur (prend toutes la largeur du navigatgeur)
.notification--forceFullWidth {
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  text-align: center; }
