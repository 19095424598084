/*
 * Page registration completed
 */

.l-registrationCompleted {
  align-items: center;
  max-width: 640px;
  margin: 0 auto;
  > .card {
    // display: flex
    width: 100%;
    box-shadow: none;
    border: 1px solid $border;

    .card-title {
      padding-top: 3rem;
      padding-bottom: 3rem; }
    .card-content {
      padding-top: 4.2rem; }
    .card-content-footer {
      margin-top: 4.2rem;
      padding-left: $block-spacing;
      padding-right: $block-spacing; } } }
