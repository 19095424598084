/*
 * Page d'inscription
 */

.l-inscription {
  $padding-h: 2.5rem;
  $padding-h--mobile: 1.5rem;

  align-items: center;
  max-width: 1208px;
  margin: 0 auto;
  > .card {
    display: flex;
    width: 100%;
    box-shadow: none;
    border: 1px solid $border;
    .card-illustration {
      width: 345px;
      max-width: 25%;
      flex: 1 0 auto;
      background-image: url('/static/dist/images/illustration-immeuble--1-min.jpg');
      background-position: 0 center;
      background-size: cover;
      @include mobile {
        display: none;
        visibility: hidden; } }
    .card-content {
      flex-grow: 1;
      padding: 0; } }

  .inscription-form {
    > * {
      padding-left: $padding-h;
      padding-right: $padding-h;
      @include mobile {
        padding-left: $padding-h--mobile;
        padding-right: $padding-h--mobile; } }
    .label {
      font-size: $size-7;
      text-transform: uppercase; }

    .title {
      padding-top: ($block-spacing * 1.75); } }
  .inscription-form-subtitle {
    padding-top: $block-spacing;
    border-top: 1px solid $border;
    p {
      line-height: 1.1;
 } }      // white-space: pre-line
  .inscription-form-message {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: $size-6;
    color: $dark;
    line-height: 1.1;
    background-color: rgb(212, 233, 241); }

  .card-content-footer {
    padding: $padding-h;
    border-top: 1px solid $border;
    @include mobile {
      padding-left: $padding-h--mobile;
      padding-right: $padding-h--mobile; } } }
